<template>
  <div id="bacteriaInfo">
    <el-dialog
      :title="bacteriaInfoFormTitle"
      width="1500px"
      :visible.sync="bacteriaInfoDialogVisible"
      :close-on-click-modal="false"
      @close="bacteriaInfoDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="bacteriaInfoFormRef"
        :model="bacteriaInfoForm"
        :rules="bacteriaInfoFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="菌种名称" prop="name">
              <el-input v-model="bacteriaInfoForm.name" placeholder="请输入菌种名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="菌种编号" prop="no">
              <el-input v-model="bacteriaInfoForm.no" placeholder="请输入菌种编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6" style="height: 62.8px;">
            <el-form-item label="菌种类型" prop="type">
              <el-radio-group v-model="bacteriaInfoForm.type">
                <el-radio :label="1">
                  原始菌
                </el-radio>
                <el-radio :label="2">
                  工作菌
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="菌种代数" prop="generation">
              <el-input v-model="bacteriaInfoForm.generation" placeholder="请输入菌种代数" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="数量" prop="quantity">
              <el-input v-model="bacteriaInfoForm.quantity" placeholder="请输入数量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="来源" prop="source">
              <el-input v-model="bacteriaInfoForm.source" placeholder="请输入来源" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="保存方式" prop="storageMethod">
              <el-input v-model="bacteriaInfoForm.storageMethod" placeholder="请输入保存方式" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="存放位置" prop="storageLocation">
              <el-input v-model="bacteriaInfoForm.storageLocation" placeholder="请输入存放位置" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资质证明上传">
              <el-scrollbar style="height: 100px">
                <el-upload
                  v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'"
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                >
                  <el-button size="small" type="primary">
                    点击上传
                  </el-button>
                </el-upload>
                <el-tree
                  v-if="bacteriaInfoFormTitle === '菌种保存、领用及销毁登记台账详情'"
                  :data="fileList"
                  node-key="path"
                >
                  <span slot-scope="{ node, data }" class="custom-tree-node">
                    <span>{{ data.name }}</span>
                    <span>
                      <el-button type="text" size="mini" @click="() => download(data)">
                        下载
                      </el-button>
                    </span>
                  </span>
                </el-tree>
              </el-scrollbar>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="传代情况">
          <vxe-toolbar v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'">
            <template #buttons>
              <vxe-button @click="insertRowEventPass()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="passTable"
            border
            resizable
            show-overflow
            keep-source
            :data="passList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="passageDate"
              title="传代日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date',clearable: true}}"
            />
            <vxe-table-column
              field="passageNo"
              title="传代菌种编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="totalAmount"
              title="总数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-colgroup title="传代用菌株" header-align="center">
              <vxe-table-column
                field="no1"
                title="编号"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="quantity1"
                title="数量"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="validPeriod1"
                title="有效期"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="saveMethod1"
                title="保存方法"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-colgroup title="工作用菌株" header-align="center">
              <vxe-table-column
                field="no2"
                title="编号"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="quantity2"
                title="数量"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="validPeriod2"
                title="有效期"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="saveMethod2"
                title="保存方法"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEventPass(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="领用情况">
          <vxe-toolbar v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'">
            <template #buttons>
              <vxe-button @click="insertRowEventUse()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="useTable"
            border
            resizable
            show-overflow
            keep-source
            :data="useList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="requisitionDate"
              title="领用日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date',clearable: true}}"
            />
            <vxe-table-column
              field="strainName"
              title="菌种名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="no"
              title="菌种编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="quantity"
              title="数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="purpose"
              title="用途"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="concn"
              title="制备菌悬液浓度"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remainQuantity"
              title="剩余数量"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="recipient"
              title="领用人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="issuer"
              title="发放人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remark"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEventUse(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="销毁情况">
          <vxe-toolbar v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'">
            <template #buttons>
              <vxe-button @click="insertRowEventDestroy()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="destroyTable"
            border
            resizable
            show-overflow
            keep-source
            :data="destroyList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="destroyDate"
              title="销毁日期"
              width="150"
              :edit-render="{name: '$input', props: {type: 'date',clearable: true}}"
            />
            <vxe-table-column
              field="name"
              title="被处理的菌种名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="no"
              title="被处理的菌种编号"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="destroyReason"
              title="销毁原因"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="processMethod"
              title="处理方式"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="operator"
              title="操作人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="supervisor"
              title="监督人"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="remark"
              title="备注"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column v-if="bacteriaInfoFormTitle !== '菌种保存、领用及销毁登记台账详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEventDestroy(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="bacteriaInfoDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="bacteriaInfoFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="bacteriaInfoFormTitle === '菌种保存、领用及销毁登记台账详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="菌种名称">
        <el-input v-model="searchForm.name" placeholder="请输入菌种名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="bacteriaInfoPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="name" label="菌种名称" />
      <el-table-column prop="no" label="菌种编号" />
      <el-table-column prop="type" label="菌种类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1">原始菌</span>
          <span v-if="scope.row.type == 2">工作菌</span>
        </template>
      </el-table-column>
      <el-table-column prop="generation" label="菌种代数" />
      <el-table-column prop="quantity" label="数量" />
      <el-table-column prop="source" label="来源" />
      <el-table-column prop="storageMethod" label="保存方式" />
      <el-table-column prop="storageLocation" label="存放位置" />
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="bacteriaInfoPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addBacteriaInfo,
  deleteBacteriaInfo,
  updateBacteriaInfo,
  selectBacteriaInfoInfo,
  selectBacteriaInfoList
} from '@/api/quality/bacteriaInfo'
import { upload, download } from '@/api/main/file'
import { saveAs } from 'file-saver'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      bacteriaInfoDialogVisible: false,
      bacteriaInfoFormTitle: '',
      bacteriaInfoForm: {
        id: '',
        name: '',
        no: '',
        type: '',
        generation: '',
        quantity: '',
        source: '',
        storageMethod: '',
        storageLocation: '',
        qualificationUrl: '',
        qualificationName: '',
        passJson: '',
        useJson: '',
        destroyJson: ''
      },
      bacteriaInfoFormRules: {
        name: [{ required: true, message: '菌种名称不能为空', trigger: ['blur', 'change']}]
      },
      bacteriaInfoPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      fileList: [],
      passList: [],
      useList: [],
      destroyList: []
    }
  },
  created () {
    selectBacteriaInfoList(this.searchForm).then(res => {
      this.bacteriaInfoPage = res
    })
  },
  methods: {
    bacteriaInfoDialogClose () {
      this.$refs.bacteriaInfoFormRef.resetFields()
      if (this.$refs.uploadRef) {
        this.$refs.uploadRef.clearFiles()
      }
      this.fileList = []
      this.passList = []
      this.useList = []
      this.destroyList = []
    },
    bacteriaInfoFormSubmit () {
      if (this.bacteriaInfoFormTitle === '菌种保存、领用及销毁登记台账详情') {
        this.bacteriaInfoDialogVisible = false
        return
      }
      this.$refs.bacteriaInfoFormRef.validate(async valid => {
        if (valid) {
          this.bacteriaInfoForm.qualificationUrl = this.fileList.map(file => file.path).join(',')
          this.bacteriaInfoForm.qualificationName = this.fileList.map(file => file.name).join(',')
          this.bacteriaInfoForm.passJson = JSON.stringify(this.$refs.passTable.getTableData().tableData)
          this.bacteriaInfoForm.useJson = JSON.stringify(this.$refs.useTable.getTableData().tableData)
          this.bacteriaInfoForm.destroyJson = JSON.stringify(this.$refs.destroyTable.getTableData().tableData)
          if (this.bacteriaInfoFormTitle === '新增菌种保存、领用及销毁登记台账') {
            this.bacteriaInfoForm.id = ''
            await addBacteriaInfo(this.bacteriaInfoForm)
          } else if (this.bacteriaInfoFormTitle === '修改菌种保存、领用及销毁登记台账') {
            await updateBacteriaInfo(this.bacteriaInfoForm)
          }
          this.bacteriaInfoPage = await selectBacteriaInfoList(this.searchForm)
          this.bacteriaInfoDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.bacteriaInfoFormTitle = '新增菌种保存、领用及销毁登记台账'
      this.bacteriaInfoDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBacteriaInfo(row.id)
        if (this.bacteriaInfoPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.bacteriaInfoPage = await selectBacteriaInfoList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.bacteriaInfoFormTitle = '修改菌种保存、领用及销毁登记台账'
      this.bacteriaInfoDialogVisible = true
      this.selectBacteriaInfoInfoById(row.id)
    },
    handleInfo (index, row) {
      this.bacteriaInfoFormTitle = '菌种保存、领用及销毁登记台账详情'
      this.bacteriaInfoDialogVisible = true
      this.selectBacteriaInfoInfoById(row.id)
    },
    selectBacteriaInfoInfoById (id) {
      selectBacteriaInfoInfo(id).then(res => {
        this.bacteriaInfoForm.id = res.id
        this.bacteriaInfoForm.name = res.name
        this.bacteriaInfoForm.no = res.no
        this.bacteriaInfoForm.type = res.type
        this.bacteriaInfoForm.generation = res.generation
        this.bacteriaInfoForm.quantity = res.quantity
        this.bacteriaInfoForm.source = res.source
        this.bacteriaInfoForm.storageMethod = res.storageMethod
        this.bacteriaInfoForm.storageLocation = res.storageLocation
        if (res.qualificationUrl) {
          const qualificationUrl = res.qualificationUrl.split(',')
          const qualificationName = res.qualificationName.split(',')
          this.fileList = []
          for (let i = 0; i < qualificationUrl.length; i++) {
            this.fileList.push({
              name: qualificationName[i],
              path: qualificationUrl[i]
            })
          }
        }
        this.passList = res.passList
        this.useList = res.useList
        this.destroyList = res.destroyList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBacteriaInfoList(this.searchForm).then(res => {
        this.bacteriaInfoPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBacteriaInfoList(this.searchForm).then(res => {
        this.bacteriaInfoPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBacteriaInfoList(this.searchForm).then(res => {
        this.bacteriaInfoPage = res
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    async insertRowEventPass (row) {
      let { row: newRow } = await this.$refs.passTable.insertAt({}, -1)
      await this.$refs.passTable.setActiveRow(newRow)
    },
    removeRowEventPass (row) {
      this.$refs.passTable.remove(row)
    },
    async insertRowEventUse (row) {
      let { row: newRow } = await this.$refs.useTable.insertAt({}, -1)
      await this.$refs.useTable.setActiveRow(newRow)
    },
    removeRowEventUse (row) {
      this.$refs.useTable.remove(row)
    },
    async insertRowEventDestroy (row) {
      let { row: newRow } = await this.$refs.destroyTable.insertAt({}, -1)
      await this.$refs.destroyTable.setActiveRow(newRow)
    },
    removeRowEventDestroy (row) {
      this.$refs.destroyTable.remove(row)
    },
    download (data) {
      console.log(data)
      download({ fileName: data.path }).then(res => {
        saveAs(res, data.name)
      })
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `菌种保存、领用及销毁登记台账${this.bacteriaInfoForm.no}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
#bacteriaInfo .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
#bacteriaInfo .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
