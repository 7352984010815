import axios from '@/common/axios'
import qs from 'qs'

export function addBacteriaInfo (data) {
  return axios({
    method: 'post',
    url: '/quality/bacteria/add',
    data: qs.stringify(data)
  })
}

export function deleteBacteriaInfo (id) {
  return axios({
    method: 'delete',
    url: '/quality/bacteria/delete/' + id
  })
}

export function updateBacteriaInfo (data) {
  return axios({
    method: 'put',
    url: '/quality/bacteria/update',
    data: qs.stringify(data)
  })
}

export function selectBacteriaInfoInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/bacteria/info/' + id
  })
}

export function selectBacteriaInfoList (query) {
  return axios({
    method: 'get',
    url: '/quality/bacteria/list',
    params: query
  })
}
